<template>
  <div class="uodateTel">
    <div class="title">更改手机号</div>
    <div class="tips">
      <span v-if="curStep==1">验证当前手机号</span>
      <span v-else>请绑定新的手机号</span>
    </div>
    <div class="form_box">
      <el-form ref="form" :model="form" label-width="120px" v-if="curStep==1">
        <el-form-item label="当前手机号:">
          <div class="form_row_box">
            <input v-model="form.mobile" placeholder="请输入当前手机号" />
          </div>
        </el-form-item>
        <el-form-item label="验证码:">
          <div class="form_row_box">
            <input type="text" placeholder="请输入验证码" v-model="form.code">
            <div class="verify_box">
              <verifyCode :mob="form.mobile" event="resetMobile"></verifyCode>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <el-form ref="form" :model="form" label-width="120px" v-if="curStep==2">
        <el-form-item label="新手机号:">
          <div class="form_row_box">
            <input v-model="form.new_mobile" placeholder="请输入新手机号" />
          </div>
        </el-form-item>
        <el-form-item label="验证码:">
          <div class="form_row_box">
            <input type="text" placeholder="请输入验证码" v-model="form.new_code">
            <div class="verify_box">
              <verifyCodeTwo :mob="form.new_mobile" event="resetMobileNew"></verifyCodeTwo>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div class="comfirm_btn_box">
        <button class="comfirm_btn" v-if="curStep==1" @click="nextStep">下一步 </button>
        <button class="comfirm_btn" v-else @click="submitBind">绑定 </button>
      </div>
    </div>
  </div>
</template>
  
<script>
import verifyCode from '../../../components/verifyCode/verifyCode.vue';
import verifyCodeTwo from '../../../components/verifyCode/verifyCode.vue';
export default {
  components: {
    verifyCode,
    verifyCodeTwo
  },
  data () {
    return {
      curStep: 1,
      form: {
        mobile: '',
        code: '',
        new_mobile: '',
        new_code: '',
      }
    }
  },
  methods: {
    nextStep () {
      if (!this.$regs.isPhone.test(this.form.mobile)) {
        this.$message.error('手机格式不正确');
        return;
      }
      if (this.form.mobile.length && this.form.code.length) {
        this.curStep = 2;
      } else {
        this.$message.error('请输入验证码');
      }
    },
    submitBind () {
      if (!this.$regs.isPhone.test(this.form.new_mobile)) {
        this.$message.error('手机格式不正确');
        return;
      }
      if (this.form.new_mobile.length && this.form.new_code.length) {
        this.$api.resetMobile(this.form).then(res => {
          if (res.code == 1) {
            this.$message.success(res.msg);
            setTimeout(() => {
              location.reload();
            }, 1500)
          }
        });
      } else {
        this.$message.error('请输入验证码');
      }
    }
  }
}
</script>
  
<style lang="scss" scoped>
.uodateTel {
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;

  .title {
    font-size: 22px;
    font-weight: 500;
    color: #2f2f2f;
    line-height: 26px;
    padding: 21px 0 16px 16px;
  }

  .tips {
    font-size: 13px;
    font-weight: 500;
    color: #3f63ff;
    line-height: 16px;
    text-align: center;
    padding: 16px 0;
    background: #ecf0f7;
    border-radius: 0px 0px 0px 0px;
  }
  .form_box {
    padding: 104px 137px;
    .form_row_box {
      display: flex;
      align-items: center;
      width: 375px;
      height: 50px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      padding: 0 14px;
      opacity: 1;
      border: 1px solid #e5e5e5;
      .verify_box {
        width: 125px;
        height: 33px;
        opacity: 1;
        flex: none;
      }
      input {
        flex: 1;
      }
    }
  }
  .comfirm_btn_box {
    display: flex;
    justify-content: center;
    .comfirm_btn {
      padding: 11px 143px;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
      background: #3f63ff;
      border-radius: 10px 10px 10px 10px;
      margin: 58px auto;
      cursor: pointer;
    }
  }
}
</style>